// src/components/Layout/Navigation.tsx
import React from 'react';
import useScrollTo from '../../hooks/useScrollTo';

interface NavigationProps {
  isOpen: boolean;
  isMobile: boolean;
  onClose: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ isOpen, isMobile, onClose }) => {
  const scrollTo = useScrollTo();
  const className = `${isOpen && isMobile ? 'db' : ''}`;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    onClose();
    scrollTo(id);
  };

  return (
    <nav id="menubar" className={className}>
      <ul>
        <li><a href="#business" onClick={(e) => handleClick(e, 'business')}>BUSINESS</a></li>
        <li><a href="#news" onClick={(e) => handleClick(e, 'news')}>NEWS</a></li>
        <li><a href="#product" onClick={(e) => handleClick(e, 'product')}>PRODUCT</a></li>
        <li><a href="#recruit" onClick={(e) => handleClick(e, 'recruit')}>RECRUIT</a></li>
        <li><a href="#company" onClick={(e) => handleClick(e, 'company')}>COMPANY</a></li>
        <li><a href="#contact" onClick={(e) => handleClick(e, 'contact')}>CONTACT</a></li>
        {/*
        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
        */}
      </ul>
    </nav>
  );
};

export default Navigation;