// src/components/Home/Business/BusinessCard.tsx
import React from 'react';
import useInView from '../../../hooks/useInView';

interface BusinessCardProps {
  image: string;
  title: string;
  description: string;
}

const BusinessCard: React.FC<BusinessCardProps> = ({ image, title, description }) => {
  const { ref, isInView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref as React.RefObject<HTMLDivElement>}
      className={`list blur ${isInView ? 'blurstyle' : ''}`}  // 'inview' から 'blurstyle' に変更
    >
      <figure>
        <img src={`/images/${image}`} alt={title} />
      </figure>
      <div className="text">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default BusinessCard;