// src/components/Layout/Header.tsx
import React, { useState, useEffect } from 'react';
import Navigation from './Navigation';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  let lastScrollY = 0;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      // スクロール量が100pxを超えたらロゴを非表示に
      if (currentScrollY > 100 && isVisible) {
        setIsVisible(false);
      } else if (currentScrollY <= 100 && !isVisible) {
        setIsVisible(true);
      }
      
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className={isMenuOpen ? 'menu-open' : ''}>
      {/* ロゴ */}
      <h1 id="logo" style={{ opacity: isVisible ? 1 : 0 }}>
        <a href="/">
          <img src="/images/logo.png" alt="SAMPLE COMPANY" />
        </a>
      </h1>

      {/* メニュー */}
      <Navigation isOpen={isMenuOpen} isMobile={isMobile} onClose={closeMenu} />

      {/* 開閉ボタン（ハンバーガーアイコン） */}
      <div 
        id="menubar_hdr" 
        className={isMenuOpen ? 'ham' : ''}
        onClick={toggleMenu}
      >
        <span></span><span></span><span></span>
      </div>
    </header>
  );
};

export default Header;