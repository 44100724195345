// src/components/Home/Recruit.tsx
import React, { useState, useEffect, useRef } from 'react';

const Recruit: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  const h2Ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('linestyle');
        }
      },
      { threshold: 0.5 }
    );
  
    if (h2Ref.current) {
      observer.observe(h2Ref.current);
    }
  
    return () => {
      if (h2Ref.current) {
        observer.unobserve(h2Ref.current);
      }
    };
  }, []);

  return (
    <section id="recruit">
      <h2 ref={h2Ref}>
        <span className="uline">Recruit</span>
      </h2>
      <dl className="faq">
        {/* SE・PG募集要項 */}
        <dt className="openclose" onClick={toggleContent}>SE・PG</dt>
        <dd style={{ display: isOpen ? 'block' : 'none' }}>
          <br />
          応募条件：<br />
          - 年齢・経験不問	<br />
          - 技術に対する熱意と意欲を重視します <br />
          <br />
          年収: 300万円〜600万円<br />
          ※経験・能力を考慮の上、優遇します。<br />
          <br />
          福利厚生：<br />
          - 社会保険完備<br />
          - 交通費支給<br />
          - 社宅制度有り<br />
          - GitHub CopilotやChatGPT等のライセンスを提供<br />
          <br />
          勤務地： <br />
          〒532-0011 大阪府大阪市淀川区西中島5丁目6-13 新大阪御幸ビル 4F<br />
          <br />
          勤務時間： <br />
          - フレックスタイム制<br />
          - コアタイム有 11:00〜15:00<br />
          - 所定労働時間 8時間 休憩1時間<br />
          <br />
          主な業務内：<br />
          - 既存システムの改修<br />
          - 新規事業のシステム設計・研究開発<br />
          - 経験が浅い方は、AIを活用した開発能力の向上を目指していただきます<br />
          <br />
          歓迎スキル：<br />
          - LLMのカスタマイズに関する知識<br />
          - プログラミング言語（C#, JavaScript, Python, Javaなど） <br />
          - フレームワーク（Flask, .Net, React/Vue, Springなど）<br />
          - データベース（SQL Server, MySQL, PostgreSQL, DynamoDBなど）<br />
          - インフラ オンプレミス環境の構築/運用経験、AWSの構築/運用<br />
        </dd>
      </dl>
    </section>
  );
};

export default Recruit;