// src/components/Layout/Footer.tsx
import React from 'react';
import useScrollTo from '../../hooks/useScrollTo';

const Footer: React.FC = () => {
  const scrollTo = useScrollTo();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    scrollTo(id);
  };

  return (
    <footer>
      <ul id="footermenu">
        <li><a href="#business" onClick={(e) => handleClick(e, 'business')}>BUSINESS</a></li>
        <li><a href="#news" onClick={(e) => handleClick(e, 'news')}>NEWS</a></li>
        <li><a href="#product" onClick={(e) => handleClick(e, 'product')}>PRODUCT</a></li>
        <li><a href="#recruit" onClick={(e) => handleClick(e, 'recruit')}>RECRUIT</a></li>
        <li><a href="#company" onClick={(e) => handleClick(e, 'company')}>COMPANY</a></li>
        <li><a href="#contact" onClick={(e) => handleClick(e, 'contact')}>CONTACT</a></li>
      </ul>

      <small>Copyright&copy; <a href="/">Cascade Inc.</a> All Rights Reserved.</small>
      <span className="pr"><a href="https://template-party.com/" target="_blank" rel="noopener noreferrer">《Web Design:Template-Party》</a></span>
    </footer>
  );
};

export default Footer;