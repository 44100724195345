// src/components/Home/News/News.tsx
import React, { useEffect, useRef } from 'react';
import NewsItem from './NewsItem';

interface NewsItemType {
  date: string;
  category: string;
  content: {
    prefix?: string;
    linkText?: string;
    linkUrl?: string;
    suffix?: string;
  };
  iconClass: string;
}

const News: React.FC = () => {
  const newsItems: NewsItemType[] = [
    {
      date: '2024/11/26',
      category: 'Product',
      content: {
        prefix: '12月18～20日にインテックス大阪で開催される',
        linkText: '「DX総合 EXPO」',
        linkUrl: 'https://www.bizcrew.jp/expo/dx-osaka',
        suffix: 'に出展します'
      },
      iconClass: 'icon-bg3'
    },
    {
      date: '2024/11/25',
      category: 'Product',
      content: {
        linkText: 'AI知識管理システム「ERUCORE」',
        linkUrl: 'https://erucore.com',
        suffix: 'の販売を開始しました'
      },
      iconClass: 'icon-bg3'
    },
    {
      date: '2024/05/01',
      category: 'Other',
      content: {
        prefix: '本社を移転しました'
      },
      iconClass: 'icon-bg2'
    },
    {
      date: '2024/02/08',
      category: 'Recruit',
      content: {
        prefix: 'SE・PGの募集を開始しました',
      },
      iconClass: 'icon-bg1'
    }
  ];

  const h2Ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('linestyle');
        }
      },
      { threshold: 0.5 }
    );

    if (h2Ref.current) {
      observer.observe(h2Ref.current);
    }

    return () => {
      if (h2Ref.current) {
        observer.unobserve(h2Ref.current);
      }
    };
  }, []);

  return (
    <section id="news">
      <h2 ref={h2Ref}>
        <span className="uline">News</span>
      </h2>

      <dl id="new">
        {newsItems.map((item, index) => (
          <NewsItem
            key={index}
            date={item.date}
            category={item.category}
            content={item.content}
            iconClass={item.iconClass}
          />
        ))}
      </dl>
    </section>
  );
};

export default News;
