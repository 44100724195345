// src/components/Home/Company.tsx
import React, { useEffect, useRef } from 'react';

const Company: React.FC = () => {
  const h2Ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('linestyle');
        }
      },
      { threshold: 0.5 }
    );
  
    if (h2Ref.current) {
      observer.observe(h2Ref.current);
    }
  
    return () => {
      if (h2Ref.current) {
        observer.unobserve(h2Ref.current);
      }
    };
  }, []);

  return (
    <section id="company">
      <h2 ref={h2Ref}>
        <span className="uline">Company</span>
      </h2>

      <table className="ta1">
        {/* caption></caption */}
        <tbody>
          <tr>
            <th>社名</th>
            <td>株式会社カスケード</td>
          </tr>
          <tr>
            <th>設立</th>
            <td>2022年10月</td>
          </tr>
          <tr>
            <th>資本金</th>
            <td>100万円</td>
          </tr>
          <tr>
            <th>代表取締役</th>
            <td>朝田　芳弘</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>
              本社：<br />
              〒532-0011 大阪府大阪市淀川区西中島5丁目6-13新大阪御幸ビル 4F<br />
              <br />
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.023025977518!2d135.49729007694123!3d34.729813972909376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e43b0f8513a1%3A0x6dc67ded48d2e18f!2z5paw5aSn6Ziq5b6h5bm444OT44Or!5e0!3m2!1sja!2sjp!4v1709887137807!5m2!1sja!2sjp" 
                width="600" 
                height="450" 
                style={{ border: 0 }} 
                allowFullScreen 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Company;