// client-homepage/src/components/Home/Contact/ContactForm.tsx

import React, { useState, useEffect, useRef } from 'react';
import { PrivacyPolicyDialog } from '../../shared/PrivacyPolicyDialog';
import './ContactForm.css';

interface FormData {
  title: string;
  company?: string;  // 任意項目
  name?: string;     // 任意項目
  email: string;
  message: string;
}

interface ValidationErrors {
  title?: string;
  company?: string;
  name?: string;
  email?: string;
  message?: string;
}

const ContactForm: React.FC = () => {
  // フォームの状態管理
  const [formData, setFormData] = useState<FormData>({
    title: '',
    company: '',
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const h2Ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('linestyle');
        }
      },
      { threshold: 0.5 }
    );
  
    if (h2Ref.current) {
      observer.observe(h2Ref.current);
    }
  
    return () => {
      if (h2Ref.current) {
        observer.unobserve(h2Ref.current);
      }
    };
  }, []);

  // メールアドレスのバリデーション関数
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // フォームバリデーション
  const validateForm = (): boolean => {
    const newErrors: ValidationErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = 'タイトルは必須です';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'メールアドレスは必須です';
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = '有効なメールアドレスを入力してください';
    }

    if (!formData.message.trim()) {
      newErrors.message = '本文は必須です';
    } else if (formData.message.length > 5000) {
      newErrors.message = '本文は5000文字以内で入力してください';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // フォーム送信処理
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitMessage('');
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_HOMEPAGE_CONTACT_FORM_URL}/homepage-contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok) {
        setSubmitMessage('お問い合わせを受け付けました。');
        setFormData({ title: '', company: '', name: '', email: '', message: '' });
      } else {
        setSubmitMessage('エラーが発生しました。もう一度お試しください。');
        if (data.errors) {
          setErrors(data.errors);
        }
      }
    } catch (error) {
      setSubmitMessage('通信エラーが発生しました。もう一度お試しください。');
    } finally {
      setIsSubmitting(false);
    }
  };

  // 入力フィールドの変更ハンドラ
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    // 入力時のエラーをクリア
    if (errors[name as keyof ValidationErrors]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  return (
    <section id="contact">
      <h2 ref={h2Ref}>
        <span className="uline">Contact</span>
      </h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="title">タイトル</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="タイトルを入力してください"
          />
          {errors.title && <span className="error">{errors.title}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="company">
            会社名
            <span className="optional">（任意）</span>
          </label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            placeholder="会社名を入力してください"
          />
          {errors.company && <span className="error">{errors.company}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="name">
            ご担当者名
            <span className="optional">（任意）</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="お名前を入力してください"
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="email">メールアドレス</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="example@email.com"
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="message">本文</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="お問い合わせ内容を入力してください"
            rows={8}
          />
          <div className="character-count">
            {formData.message.length}/5000文字
          </div>
          {errors.message && <span className="error">{errors.message}</span>}
        </div>

        {submitMessage && (
          <div className={`submit-message ${submitMessage.includes('エラー') ? 'error' : 'success'}`}>
            {submitMessage}
          </div>
        )}

        <div className="privacy-policy">
          <p>
            個人情報は、
            <button
              type="button"
              className="privacy-policy-link"
              onClick={() => setIsPrivacyPolicyOpen(true)}
            >
              プライバシーポリシー
            </button>
            に基づき適切に管理いたします。
          </p>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
        >
          {isSubmitting ? '送信中...' : 'プライバシーポリシーに同意のうえ送信する'}
        </button>
        <PrivacyPolicyDialog
          isOpen={isPrivacyPolicyOpen}
          onClose={() => setIsPrivacyPolicyOpen(false)}
        />
      </form>
    </section>
  );
};

export default ContactForm;