// src/components/shared/PageTop.tsx
import React, { useState, useEffect } from 'react';

const PageTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  // スクロール位置に応じてボタンの表示/非表示を制御
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // トップにスクロール
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={`pagetop ${isVisible ? 'visible' : ''}`}>
      <a href="#" onClick={(e) => {
        e.preventDefault();
        scrollToTop();
      }}>
        <i className="fas fa-angle-double-up"></i>
      </a>
    </div>
  );
};

export default PageTop;