// src/components/Home/MainVisual.tsx
import React from 'react';

const MainVisual: React.FC = () => {
  return (
    <div>
      <img src="/images/main.jpg" alt="メインビジュアル" />
    </div>
  );
};

export default MainVisual;