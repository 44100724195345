// src/components/Home/News/NewsItem.tsx
import React from 'react';

interface NewsItemProps {
  date: string;
  category: string;
  content: {
    prefix?: string;
    linkText?: string;
    linkUrl?: string;
    suffix?: string;
  };
  iconClass: string;
}

const NewsItem: React.FC<NewsItemProps> = ({
  date,
  category,
  content,
  iconClass
}) => {
  return (
    <>
      <dt>{date}<span className={iconClass}>{category}</span></dt>
      <dd>
        {content.prefix}
        {content.linkText && content.linkUrl && (
          <a 
            href={content.linkUrl}
            className="hover:underline text-blue-600"
          >
            {content.linkText}
          </a>
        )}
        {content.suffix}
      </dd>
    </>
  );
};

export default NewsItem;