// src/components/Home/Business/Business.tsx
import React, { useEffect, useRef } from 'react';
import BusinessCard from './BusinessCard';

const Business: React.FC = () => {
  const businessItems = [
    {
      image: 'si.jpg',
      title: 'システムインテグレーション',
      description: 'AI技術を活用しながら、世界の急速な変化に迅速かつ柔軟に適応し、絶え間ない進化を続けることで、お客様に新しい機会と最上の価値を提供します。'
    },
    {
      image: 'life.jpg',
      title: 'ライフサポート',
      description: 'AI技術を駆使してお客様の生活を効率的かつ快適にサポートします。お客様のニーズに合わせたカスタマイズサービスを提供し、充実した生活を手に入れましょう。'
    },
    {
      image: 'ent2.png',
      title: 'エンターテイメント',
      description: 'AI技術を駆使し、クリエイティビティと革新性に溢れた、未来のエンターテイメント体験を提供します。私たちと一緒に、新たなエンターテイメントの世界を楽しみましょう。'
    }
  ];

  const h2Ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('linestyle');
        }
      },
      { threshold: 0.5 }
    );
  
    if (h2Ref.current) {
      observer.observe(h2Ref.current);
    }
  
    return () => {
      if (h2Ref.current) {
        observer.unobserve(h2Ref.current);
      }
    };
  }, []);

  return (
    <section id="business">
      <h2 ref={h2Ref}>
        <span className="uline">Business</span>
      </h2>
      <p>
        私たちは、急速に発展するAI技術を駆使して、日常生活に鮮やかな彩りと感動的な体験をもたらす企業です。私たちの目指すところは、AIとの連携を通じて人々の喜びを引き出し、未知の刺激を提供することで、さらに充実した生活を実現することです。
      </p>
      <div className="list-container">
        {businessItems.map((item, index) => (
          <BusinessCard
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Business;