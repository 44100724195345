// src/components/Home/Product.tsx
import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js/dist/hls.js';

const Product: React.FC = () => {
  const h2Ref = useRef<HTMLHeadingElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('linestyle');
        }
      },
      { threshold: 0.5 }
    );

    if (h2Ref.current) {
      observer.observe(h2Ref.current);
    }

    // HLS動画の設定
    const video = videoRef.current;
    if (video && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource('/videos/erucore.m3u8');
      hls.attachMedia(video);
      // 不要なら削除
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // 自動再生する場合
        // video.play().catch(error => {
        //   console.log("Auto-play was prevented:", error);
        // });
      });
    }

    return () => {
      if (h2Ref.current) {
        observer.unobserve(h2Ref.current);
      }
    };
  }, []);

  return (
    <section id="product">
      <h2 ref={h2Ref}>
        <span className="uline">Product</span>
      </h2>
      <div className="product-content">
        <img src="/images/erucore-logo.png" alt="ERUCORE" className="erucore-logo" />
        <div className="erucore-text">
          <a href="https://erucore.com">ERUCORE</a>は高性能ワークステーションに搭載されたオフライン運用可能なAI文章管理システムです。<br />
          DX推進の基盤を構築し、セキュリティと業務効率化を同時に実現します。
        </div>
        <div className="video-container">
          <video 
            ref={videoRef}
            controls 
            playsInline
            className="video-player"
          />
        </div>
        <div className="erucore-link"><a href="https://erucore.com">ERUCOREの詳細はこちら &gt;&gt;</a></div>
        
        <div className="expo-container">
          <a href="https://www.bizcrew.jp/expo/dx-osaka" target="_blank" rel="noopener noreferrer">
            <img 
              src="/images/dx-expo-winter-2024.jpg" 
              alt="DX総合EXPO 2024 冬 大阪" 
              className="expo-image"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Product;